import React from "react"
import SEO from "../components/seo"

const ArchivePage = () => (
  <>
    <SEO title="archive" />
    <p className="text-md">
              <strong>Class name:</strong> Myth and Magic in the Modern Short Story
            </p>

            <p className="text-md">
              <strong>Instructor:</strong> Dipika Mukherjee is the author of the novels <em>Shambala Junction</em> and <em>Ode to Broken Things</em>, and the story collection, <em>Rules of Desire</em>. She teaches at StoryStudio Chicago and at the Graham School at University of Chicago. 
            </p>

            <p className="text-md">
              <strong>Course description:</strong> Using examples of short literary texts set in Asia and the diaspora, this workshop will examine ways in which myth and magic can be used to focus on nationalism and indigenous geographies. We will examine the role of the modern writer in a gendered, ageist, urbanized, and postcolonial world. Participants will be encouraged to explore the role of myths in their own communities to write their own short story.
            </p>
  </>
)
export default ArchivePage